export type ReplyModalState = {
  open: boolean;
  reviewId: number | undefined;
  reviewType: string | undefined | null;
}

interface CloseEvent {
  type: 'closeReply';
}

interface ReplyToReviewEvent {
  type: 'replyToReview';
  reviewId: number;
  reviewType: string | undefined | null;
}

export type ReplyModalEvent =
  | CloseEvent
  | ReplyToReviewEvent;

export const replyModalInitialState: ReplyModalState = {
  open: false,
  reviewId: undefined,
  reviewType: undefined,
};

export const replyModalReducer = (
  prevState: ReplyModalState = replyModalInitialState,
  event: ReplyModalEvent,
): ReplyModalState => {
  switch (event.type) {
    case 'closeReply':
      return replyModalInitialState;
    case 'replyToReview':
      return { open: true, reviewId: event.reviewId, reviewType: event.reviewType };
    default:
      return prevState;
  }
};
