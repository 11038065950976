import { Ability, AbilityBuilder } from '@casl/ability';
import type { AdminCurrentSession, AdminCurrentUser } from '~/shared/CurrentSessionProvider';

/*
  CASL library for client-side policy checks:
  https://github.com/stalniy/casl

  IMPORTANT: ENSURE ALL ABILITIES MATCH SERVER-SIDE PUNDIT POLICIES

  Ability helpers:
  1. Can component: `<Can do="action" on={subject} />`
  2. can function from withAbility HOC: `props.can("action", subject)`

  Allow abilities by comparing subject.restaurantId with the matching member.restaurantId access level-
  or subject.locationId with member.locations.locationId for location-specific models.

  Higher-level users are always allowed for lower-level abilities:
  member.employee < member.owner < user.root_staging < user.root_sales < user.root_marketing < user.root_boost < user.root_support < user.root_system
  ex: popmenuOwnersCan will disallow employees, but allow owners and all root users

  Params:
  - action, string  - correlates with Pundit policy actions/methods
  - subject, object - GQL type with restaurantId and/or locationId attribute-
                      subject can sometimes be a string for root-level policies
 */

// Look at Apollo __typename attribute to determine object type
const subjectName = (item: string | { __typename?: string }) => {
  if (typeof item === 'string') {
    return item.replace('::', '');
  }
  return item?.__typename ?? '';
};

export type RestaurantMember = NonNullable<NonNullable<AdminCurrentUser>['members']>[0];
export type RestaurantMemberLocation = RestaurantMember['locations'][0];
type Member = RestaurantMember | RestaurantMemberLocation;
type FilterPermissions<Set> = Set extends `can${infer _X}` ? Set : never;
export type LocationPermission = FilterPermissions<keyof RestaurantMemberLocation>;
export type RestaurantPermission = FilterPermissions<keyof RestaurantMember>;
export type Permission = LocationPermission | RestaurantPermission;

export function isMemberPermitted(member: RestaurantMember, permissions?: RestaurantPermission[]): boolean;
export function isMemberPermitted(member: RestaurantMemberLocation, permissions?: LocationPermission[]): boolean;
export function isMemberPermitted(member: Member, permissions?: Permission[]): boolean;
export function isMemberPermitted(member: Member, permissions: Permission[] = []) {
  if (permissions.includes('canManageThemes')) {
    // canManageThemes is a special case and is NOT automatically granted to a restaurant owner
    // in order to have the manage themes permission, the member must be an owner AND have canManageThemes permission;
    return ('canManageThemes' in member && !!member.canManageThemes && member.memberLevel === 'owner');
  }
  // @ts-expect-error TypeScript can't see we check the correct permission type
  // and even if we don't, we'll just get false.
  return member.memberLevel === 'owner' || permissions.every(permission => !!member[permission]);
}

const initAbility = (session: AdminCurrentSession) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method -- use endorsed by documentation
  const { can, rules } = AbilityBuilder.extract();

  // Session, User, RestaurantMember, RestaurantMember.owner
  const { user } = session;
  const members = user?.members ?? [];

  // ABILITY RULE HELPERS
  // Setting can(ability, subject) here allows all subsequent <Can /> checks to pass
  // CASL builds up `rules` functions behind the scenes
  type Ability = string | string[];

  // Limit subject to root_system only
  const popmenuSystemRootCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot && user.userRole === 'root_system') {
      can(ability, subject);
    }
  };

  // Limit subject to root_support and higher
  const popmenuRootBoostCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot && user.userRole === 'root_boost') {
      can(ability, subject);
    }
    popmenuSystemRootCan(ability, subject);
  };

  // Limit subject to root_support and higher
  const popmenuRootSupportCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot && user.userRole === 'root_support') {
      can(ability, subject);
    }
    popmenuSystemRootCan(ability, subject);
  };

  const popmenuRootSalesOpsCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot && user.userRole === 'root_sales_ops') {
      can(ability, subject);
    }
    popmenuSystemRootCan(ability, subject);
  };

  //  Limit subject to root_marketing and higher
  const popmenuRootMarketingCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot && user.userRole === 'root_marketing') {
      can(ability, subject);
    }
    popmenuSystemRootCan(ability, subject);
  };

  // Limit subject to root_sales and higher
  const popmenuRootCan = (ability: Ability, subject: unknown) => {
    if (user && user.isRoot) {
      can(ability, subject);
    }
  };

  // Limit subject to root_staging and higher
  const popmenuRootStagingCan = (ability: Ability, subject: unknown) => {
    if (user && (user.isRoot || user.isRootStaging)) {
      can(ability, subject);
    }
  };

  // Limit subject to root_opentable and higher
  const popmenuRootOpenTableCan = (ability: Ability, subject: unknown) => {
    if (user && user.userRole === 'root_opentable') {
      can(ability, subject);
    }
  };

  // Limit subject to restaurant owners and higher
  const restaurantOwnersCan = (ability: Ability, subject: unknown) => {
    const idAttr = subject === 'Restaurant' ? 'id' : 'restaurantId';
    members.filter(member => member.memberLevel === 'owner').forEach((member) => {
      can(ability, subject, { [idAttr]: member.restaurantId });
    });
    popmenuRootStagingCan(ability, subject);
  };

  // Limit subject to location managers, restaurant owners and higher
  const locationManagersCan = (ability: Ability, subject: unknown) => {
    const idAttr = subject === 'RestaurantLocation' ? 'id' : 'locationId';
    members.forEach((member) => {
      member.locations.filter(memberLocation => memberLocation.memberLevel === 'manager')
        .forEach((memberLocation) => {
          can(ability, subject, { [idAttr]: memberLocation.locationId });
        });
    });
    restaurantOwnersCan(ability, subject);
  };

  // Limit subject to restaurant employees and higher
  // Also limit subject to restaurant members where specific member.attribute == true
  const restaurantMembersCan = (ability: Ability, subject: unknown, permissions: RestaurantPermission[] = []) => {
    const idAttr = subject === 'Restaurant' ? 'id' : 'restaurantId';
    const permittedMembers = members.filter(member => isMemberPermitted(member, permissions));
    permittedMembers.forEach(member => can(ability, subject, { [idAttr]: member.restaurantId }));
    popmenuRootStagingCan(ability, subject);
  };

  // this is a version of the above functions with fallthrough permissions
  // Restaurant owner > Restaurant::Member permission > Location manager > Restaurant::Member::Location permission
  const anyMemberLevelCan = (ability: Ability, subject: unknown, permissions: Permission[] = []) => {
    if (subject === 'Restaurant') {
      throw new Error('Restaurant subject not supported');
    }
    const permittedMembers = members.filter(member => isMemberPermitted(member, permissions));
    permittedMembers.forEach(({ restaurantId }) => can(ability, subject, { restaurantId }));

    const idAttr = subject === 'RestaurantLocation' ? 'id' : 'locationId';
    members.forEach((member) => {
      const allMemberLocationsPermitted = permittedMembers.some(({ id }) => id === member.id);
      let permittedMemberLocations = member.locations;
      if (!allMemberLocationsPermitted) {
        permittedMemberLocations = permittedMemberLocations.filter(memberLocation => isMemberPermitted(memberLocation, permissions));
      }
      permittedMemberLocations.forEach(({ locationId }) => can(ability, subject, { [idAttr]: locationId }));
    });
    popmenuRootStagingCan(ability, subject);
  };

  // AI Marketing
  restaurantMembersCan('manage_ai_marketing', 'Restaurant', ['canManageAiMarketing']);
  popmenuRootBoostCan('show', 'AiMarketingContent');
  popmenuRootCan('show', 'AiMarketingContent');

  // AI Answering
  restaurantMembersCan('manage_answering', 'Restaurant', ['canManageAnyLocationAnswering']);

  // AlohaAccount
  popmenuRootBoostCan('admin_index', 'AlohaAccount');
  popmenuRootSupportCan('admin_index', 'AlohaAccount');

  // AnnouncementPolicy
  restaurantMembersCan('manage_announcements', 'Restaurant', ['canManageAnnouncements']);
  restaurantMembersCan('show', 'Announcement', ['canManageAnnouncements']);
  can('show', 'Announcement', { featured: true });
  restaurantMembersCan('create', 'Announcement', ['canManageAnnouncements']);
  restaurantMembersCan('update', 'Announcement', ['canManageAnnouncements']);
  restaurantMembersCan('destroy', 'Announcement', ['canManageAnnouncements']);

  // Multi-location team member billing permissions
  popmenuRootCan('view_billing', 'Restaurant');
  popmenuRootCan('manage_billing', 'Restaurant');
  popmenuRootCan('view_billing', 'RestaurantLocation');
  popmenuRootCan('manage_billing', 'RestaurantLocation');
  restaurantOwnersCan('manage_billing', 'Restaurant');
  restaurantOwnersCan('view_billing', 'Restaurant');
  restaurantOwnersCan('manage_billing', 'RestaurantLocation');
  restaurantOwnersCan('view_billing', 'RestaurantLocation');
  anyMemberLevelCan('manage_billing', 'RestaurantLocation', ['canManageBillingAccounts']);
  anyMemberLevelCan('view_billing', 'RestaurantLocation', ['canViewBillingAccounts']);
  anyMemberLevelCan('view_billing', 'RestaurantLocation', ['canManageBillingAccounts']);
  restaurantMembersCan('manage_billing', 'Restaurant', ['canManageBillingAccounts']);
  restaurantMembersCan('view_billing', 'Restaurant', ['canManageBillingAccounts']);
  restaurantMembersCan('view_billing', 'Restaurant', ['canViewBillingAccounts']);

  // Billing
  popmenuSystemRootCan('create', 'BillingCoupon');
  popmenuSystemRootCan('create', 'BillingPlan');
  popmenuRootCan('create', 'BillingInvoice');
  popmenuRootCan('create', 'BillingSubscription');
  popmenuRootCan('update', 'BillingSubscription');
  popmenuSystemRootCan('destroy', 'BillingSubscription');
  popmenuSystemRootCan('attach', 'BillingCustomer');
  restaurantOwnersCan('destroy', 'BillingBankAccount');
  restaurantOwnersCan('destroy', 'BillingCreditCard');

  // BlogPostPolicy
  restaurantOwnersCan('manage_blog_posts', 'Restaurant');
  restaurantOwnersCan('create', 'BlogPost');
  restaurantOwnersCan('update', 'BlogPost');
  restaurantOwnersCan('destroy', 'BlogPost');
  restaurantOwnersCan('show', 'BlogPost');
  can('show', 'BlogPost', { isEnabled: true });

  // CalendarEventPolicy
  restaurantOwnersCan('manage_calendar_events', 'Restaurant');
  restaurantOwnersCan('show', 'CalendarEvent');
  can('show', 'CalendarEvent');
  restaurantOwnersCan('create', 'CalendarEvent');
  restaurantOwnersCan('update', 'CalendarEvent');
  restaurantOwnersCan('destroy', 'CalendarEvent');

  // Chatbot
  popmenuSystemRootCan('show', 'Chatbot');

  // CuisineType
  popmenuSystemRootCan('update', 'CuisineType');

  // CustomPagePolicy
  restaurantOwnersCan('manage_custom_pages', 'Restaurant');
  restaurantOwnersCan('show', 'CustomPage');
  can('show', 'CustomPage', { isEnabled: true });
  restaurantOwnersCan('create', 'CustomPage');
  restaurantOwnersCan('update', 'CustomPage');
  restaurantOwnersCan('destroy', 'CustomPage');
  popmenuSystemRootCan('show', 'LighthouseReport');
  popmenuRootCan('manage_textbox_wysiwyg', 'CustomPage');
  popmenuRootCan('manage_textbox_wysiwyg', 'CustomPageDraft');

  // DishPolicy + MenuPolicy + MenuItemPolicy
  restaurantMembersCan('manage_dishes', 'Restaurant', ['canManageDishes']);
  restaurantMembersCan('manage_dish_tags', 'Restaurant', ['canManageDishes']);
  restaurantMembersCan('destroy', 'Dish', ['canManageDishes']);
  restaurantMembersCan('manage_item_list', 'Restaurant', ['canManageItemList']);
  restaurantMembersCan('update', 'Dish', ['canManageDishes']);
  locationManagersCan('update', 'Menu');
  restaurantMembersCan('update', 'Menu', ['canManageDishes']);
  restaurantMembersCan('destroy', 'Menu', ['canManageDishes']);
  popmenuRootStagingCan('promote_to_master', 'Menu');
  popmenuRootOpenTableCan('manage_dishes', 'Restaurant');
  popmenuRootOpenTableCan('manage_item_list', 'Restaurant');
  popmenuRootOpenTableCan('manage_dish_tags', 'Restaurant');
  popmenuRootOpenTableCan('destroy', 'Dish');
  popmenuRootOpenTableCan('update', 'Dish');
  popmenuRootOpenTableCan('update', 'Menu');
  popmenuRootOpenTableCan('destroy', 'Menu');

  // CustomFilePolicy
  popmenuRootOpenTableCan('manage_custom_files', 'Restaurant');
  restaurantMembersCan('manage_custom_files', 'Restaurant', ['canManageCustomFiles']);
  restaurantOwnersCan('show', 'CustomFile');
  can('show', 'CustomFile', { isEnabled: true });
  restaurantOwnersCan('create', 'CustomFile');
  restaurantOwnersCan('update', 'CustomFile');
  restaurantOwnersCan('destroy', 'CustomFile');

  // RootDishTagPolicy
  popmenuRootSupportCan('create', 'RootDishTag');

  // RootExtraGroupPolicy
  popmenuRootSupportCan('create', 'RootExtraGroup');

  // FollowerPolicy
  restaurantMembersCan('manage_followers', 'Restaurant', ['canManageAnyLocationMessages']);

  // FormPolicy
  restaurantMembersCan('manage_forms', 'Restaurant', ['canManageAnyLocationMessages']);

  // LoginCarouselSlidePolicy
  popmenuRootMarketingCan('show', 'LoginCarouselSlide');

  // MasterMessageCampaignPolicy
  popmenuRootCan('show', 'MasterMessageCampaign');
  popmenuSystemRootCan('create', 'MasterMessageCampaign');
  popmenuSystemRootCan('update', 'MasterMessageCampaign');
  popmenuSystemRootCan('destroy', 'MasterMessageCampaign');

  // MicrosOrg
  popmenuRootBoostCan('admin_index', 'MicrosOrg');

  // MenuItemCartPolicy
  restaurantMembersCan('manage_ordering', 'Restaurant', ['canManageAnyLocationOrders']);
  restaurantMembersCan('manage_ordering_settings', 'Restaurant', ['canManageAnyLocationOrderSettings']);

  // MessagePolicy
  restaurantMembersCan('manage_messages', 'Restaurant', ['canManageAnyLocationMessages']);
  restaurantMembersCan('manage_communication', 'Restaurant', ['canManageAnnouncements', 'canManageMessages', 'canManageSocialPosts']);
  restaurantMembersCan('manage_campaign_consolidation', 'Restaurant', ['canManageCampaignConsolidation']);
  restaurantMembersCan('manage_dish_share', 'Restaurant', ['canManageMessages', 'canManageSocialPosts']);

  // OfferPolicy
  restaurantMembersCan('manage_offers', 'Restaurant', ['canManageOffers']);

  // OrderEventPolicy
  restaurantOwnersCan('manage_ordering_events', 'Restaurant');
  restaurantOwnersCan('show', 'OrderingEvent');
  can('show', 'OrderingEvent');
  restaurantOwnersCan('create', 'OrderingEvent');
  restaurantOwnersCan('update', 'OrderingEvent');
  restaurantOwnersCan('destroy', 'OrderingEvent');

  // OrderNerd
  popmenuRootCan('demo', 'OrderNerd');

  // RestaurantPolicy
  can('show', 'Restaurant');
  restaurantMembersCan('admin_show', 'Restaurant');
  popmenuRootCan('create', 'Restaurant');
  restaurantOwnersCan('update', 'Restaurant');
  popmenuRootSupportCan('destroy', 'Restaurant');
  popmenuRootStagingCan('manage_import', 'Restaurant');
  restaurantOwnersCan('manage_nav_links', 'Restaurant');
  restaurantMembersCan('manage_themes', 'Restaurant', ['canManageThemes']);
  popmenuRootCan('show_dashboard_notices', 'Restaurant');
  restaurantOwnersCan('manage_google_my_business', 'Restaurant');
  popmenuRootSupportCan('manage_twilio', 'Restaurant');
  restaurantOwnersCan('manage_order_payments', 'Restaurant');

  // ToastRestaurant
  popmenuRootBoostCan('admin_index', 'ToastRestaurant');
  popmenuRootSupportCan('admin_index', 'ToastRestaurant');

  // NcrPaymentAccount
  popmenuRootBoostCan('admin_index', 'NcrPaymentAccount');

  // RestaurantLocationPolicy
  popmenuRootCan('create', 'RestaurantLocation');
  popmenuRootCan('destroy', 'RestaurantLocation');
  locationManagersCan('update', 'RestaurantLocation');
  restaurantOwnersCan('manage_order_payments', 'RestaurantLocation');

  // RestaurantMemberPolicy
  popmenuRootCan('manage_admin_permissions', 'RestaurantMember');
  popmenuRootCan('view_history', 'RestaurantMember');
  restaurantOwnersCan('manage_members', 'Restaurant');
  restaurantOwnersCan('show', 'RestaurantMember');
  restaurantOwnersCan('create', 'RestaurantMember');
  restaurantOwnersCan('update', 'RestaurantMember');
  restaurantOwnersCan('destroy', 'RestaurantMember');

  // ReviewPolicy
  restaurantOwnersCan('update', 'GoogleMyBusinessLocationReview');
  restaurantMembersCan('manage_reviews', 'Restaurant', ['canManageReviews']);
  anyMemberLevelCan('manage_reviews', 'Review', ['canManageReviews']);

  // FeedbackPolicy
  restaurantMembersCan('manage_feedback', 'Restaurant', ['canManageFeedback']);

  // QrCardPolicy
  restaurantOwnersCan('manage_qr_cards', 'Restaurant');

  // Root
  popmenuRootCan('show', 'Root');
  popmenuRootSupportCan('test', 'Root');
  popmenuSystemRootCan('create', 'User');
  popmenuSystemRootCan('destroy', 'User');
  popmenuRootSupportCan('export_all', 'User');
  popmenuRootSupportCan('create', 'RootTheme');
  popmenuSystemRootCan('create', 'LiveDomain');

  // RootGroup
  popmenuRootCan('create', 'RootGroup');

  // RootTask
  popmenuSystemRootCan('create', 'RootTask');

  // Shop Policy
  restaurantMembersCan('manage_gift_cards', 'Restaurant', ['canManageGiftCards']);

  // SocialAccount/SocialPostPolicy
  restaurantOwnersCan('create', 'SocialPost');
  restaurantOwnersCan('destroy', 'SocialPost');
  restaurantMembersCan('manage_social_accounts', 'Restaurant', ['canManageSocialPosts']);
  restaurantMembersCan('manage_social_posts', 'Restaurant', ['canManageSocialPosts']);
  restaurantMembersCan('destroy', 'GooglePost');

  // Woflow Import Policy
  popmenuRootStagingCan('show', 'WoflowImport');

  // Support Utility Policy
  popmenuRootSupportCan('show', 'SupportUtility');
  popmenuRootSalesOpsCan('show', 'SupportUtility');
  popmenuRootSalesOpsCan('showSalesforceTab', 'SupportUtility');
  popmenuSystemRootCan('showTwilioTab', 'SupportUtility');

  return new Ability(rules, { subjectName });
};

export default initAbility;
