
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "followersAllFollowers": {
      "id": "followers.all_followers",
      "defaultMessage": "All followers"
    },
    "followersByLocation": {
      "id": "followers.location",
      "defaultMessage": "{follower_count} followers"
    },
    "followersImportFollower": {
      "id": "followers.import.follower",
      "defaultMessage": "Add Follower"
    },
    "followersExportTitle": {
      "id": "followers.export.title",
      "defaultMessage": "Export Followers"
    },
    "followersQrCodeTitle": {
      "id": "followers.qrCode.title",
      "defaultMessage": "Generate QR Code"
    },
    "followersExportCsv": {
      "id": "followers.export.csv",
      "defaultMessage": "Export to CSV"
    },
    "followersImportTitle": {
      "id": "followers.import.title",
      "defaultMessage": "Import Followers"
    },
    "followersImportAvailableFields": {
      "id": "followers.import.available_fields",
      "defaultMessage": "Available Fields"
    },
    "followersImportImportedFields": {
      "id": "followers.import.imported_fields",
      "defaultMessage": "Uploaded Fields"
    },
    "followersTagsManage": {
      "id": "followers.tags.manage",
      "defaultMessage": "Manage Tags"
    },
    "followersSettings": {
      "id": "followers.settings",
      "defaultMessage": "User Settings"
    },
    "followersTagsEdit": {
      "id": "followers.tags.edit",
      "defaultMessage": "Edit Tags"
    },
    "followersImportUpdateFollower": {
      "id": "followers.import.update_follower",
      "defaultMessage": "Update Follower"
    },
    "followersDestroyConfirm": {
      "id": "followers.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this follower?"
    },
    "followersAdd": {
      "id": "followers.add",
      "defaultMessage": "Add Follower"
    },
    "followersIndex": {
      "id": "followers.index",
      "defaultMessage": "Followers"
    },
    "followerTabsMessages": {
      "id": "follower.tabs.messages",
      "defaultMessage": "Messages"
    },
    "followerTabsPops": {
      "id": "follower.tabs.pops",
      "defaultMessage": "Pops"
    },
    "followerTabsReviews": {
      "id": "follower.tabs.reviews",
      "defaultMessage": "Reviews"
    },
    "followerTabsVisits": {
      "id": "follower.tabs.visits",
      "defaultMessage": "Item Views"
    },
    "followerTabsOrders": {
      "id": "follower.tabs.orders",
      "defaultMessage": "Orders"
    }
  }
);
export default defaultMessages;

